<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <div class="d-flex justify-space-between">
        <p class="font-weight-bold title black--text">Micro Influencer</p>
        <p @click="openAll()" class="primary--text text-14 pt-1 font-weight-bold pointer">Lihat Lainnya</p>
      </div>
      <div class="row" >
        <v-slide-group
          v-if="isLoading"
          class="pb-4 row pl-3 pt-3"
          active-class="success"
        >
          <v-slide-item v-for="n in 3" :key="n">
            <v-card
              width="150px"
              height="270px"
              class="round ma-3"
              elevation="0"
              outlined
            >
              <v-skeleton-loader
                type="card"
              ></v-skeleton-loader>
            </v-card>         
          </v-slide-item>
        </v-slide-group>
        <v-slide-group
          v-else
          class="pb-4 row pl-3 pt-3"
          active-class="success"
          >
          <v-slide-item
              v-for="list in listInfluencers"
              :key="list.id"
          >
          <!-- KETIKA LOGIN -->
            <v-card
              v-if="isLogin"
              width="150px"
              height="270px"
              class="round ma-3"
              elevation="0"
              outlined
            >
              <!-- <v-img @click="goToDetail(list.id)" height="150" :src="list.photo" /> -->
              <v-img
                class="pointer" @click="goToDetail(list.id)" height="150"
                :src="list.photo"
              >
                <v-app-bar
                  flat
                  color="rgba(0, 0, 0, 0)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="list.isPriority === 1"
                    elevation="0"
                    color="white"
                    fab
                    x-small
                    class="recommended"
                    aria-label="recomended"
                  >
                    <v-icon class="primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon>
                  </v-btn>
                </v-app-bar>
              </v-img>
              <v-card-text class="pa-2 pb-0">
                <p v-if="list.name.length < 14" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,14)+".."}}</p>
                <v-btn @click="book(list.instagram)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
                <p v-if="list.instagram.length < 20" class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{ list.instagram }}</p>
                <p v-else class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{list.instagram.substring(0,20)+".."}}</p>
                <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
              </v-card-text>
            </v-card>
            <!-- KETIKA BELUM LOGIN -->
            <v-card
              v-else
              width="150px"
              height="270px"
              class="round ma-3"
              elevation="0"
              outlined
            >
              <!-- <v-img class="pointer" @click="goToDetail(list.id)" height="150" :src="list.photo" /> -->
              <v-img
                class="pointer" @click="goToDetail(list.id)" height="150"
                :src="list.photo"
              >
                <v-app-bar
                  flat
                  color="rgba(0, 0, 0, 0)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="list.isPriority === 1"
                    elevation="0"
                    color="white"
                    fab
                    x-small
                    class="recommended"
                    aria-label="recomended"
                  >
                    <v-icon class="primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon>
                  </v-btn>
                </v-app-bar>
              </v-img>
              <v-card-text class="pa-2 pb-0">
                <p class="left-text black--text text-14 font-weight-bold mb-1">******** <i style="float:right" class="fas fa-eye-slash pt-0 mt-0 text-12"></i></p>
                <v-btn @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye mr-2"></i> Unblock</v-btn>
                <p class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>********</p>
                <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
              </v-card-text>
            </v-card> 
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
    <!-- DESKTOP -->
    <div v-else>
      <v-card class="round-16" color="transparent" elevation="0">
        <v-card-title class="pb-2 pt-3 pl-0 pr-0 d-flex justify-space-between">
          <p class="title font-weight-bold black--text mb-0">Micro Influencer</p>
          <v-btn class="text-capitalize" color="primary" elevation="3" outlined rounded @click="openAll()">See More</v-btn>
          <!-- <p @click="openAll()" class="primary--text text-14 pt-1 font-weight-bold pointer">See More</p> -->
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <div class="" >
            <div
              v-if="isLoading"
              class="row pb-4 pl-6 pr-6 pt-3 d-flex justify-space-between"
              active-class="success"
            >
              <div v-for="n in 6" :key="n">
                <v-card
                  width="180px"
                  height="270px"
                  class="round mb-2"
                  elevation="0"
                  outlined
                >
                  <v-skeleton-loader
                    type="card"
                  ></v-skeleton-loader>
                </v-card>         
              </div>
            </div>
            <div v-else class="d-flex justify-space-between">
              <!-- <div > -->
              <!-- KETIKA LOGIN -->
                <v-card v-for="list in listInfluencers"
                  :key="list.id"
                  width="230"
                  height="270px"
                  class="round-16"
                  elevation="3"
                  outlined
                >
                  <v-img
                    class="pointer" @click="goToDetail(list.id)" height="150"
                    :src="list.photo"
                  >
                    <v-app-bar
                      flat
                      color="rgba(0, 0, 0, 0)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="list.isPriority === 1"
                        elevation="0"
                        color="white"
                        fab
                        x-small
                        class="recommended"
                        aria-label="recomended"
                      >
                        <v-icon class="primary--text">
                          {{ icons.mdiLightningBolt }}
                        </v-icon>
                      </v-btn>
                    </v-app-bar>
                  </v-img>
                  <v-card-text class="pa-2 pb-0 black--text">
                    <p v-if="list.name.length < 19" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                    <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,19)+".."}}</p>
                    <p v-if="list.instagram.length < 20" class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{ list.instagram }}</p>
                    <p v-else class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{list.instagram.substring(0,20)+".."}}</p>
                    <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
                    <div class="d-flex justify-center">
                        <v-btn @click="book(list.instagram)" elevation="1" outlined rounded class="text-capitalize mb-2 mt-2 text-10 mr-1" small color="primary">View Profile</v-btn>

                        <v-btn @click="book(list.instagram)" elevation="1" rounded class="text-capitalize mb-2 mt-2 text-10 ml-1" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              <!-- </div> -->
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mdiLightningBolt } from '@mdi/js'

export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      loadingImage:true,
      isLogin:true,
      listInfluencers:[],
      isMobileWidth:0,
      icons:{
        mdiLightningBolt
      }
    }
  },
  mounted(){
    if(!localStorage.getItem('token')){
      this.isLogin = false
      this.loadInfluencersNot()
    } else {
      this.isLogin=true
      this.loadInfluencers()
    }
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadInfluencers(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getInfluencersHomeLogin/b/'+0, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          // this.isLoading = false;
          // this.loadingImage = true
          if(response){
            let data = response.data;
            let num = 1
            for(let a=0; a < 5; a++){
              data[a].num = num++
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
              this.listInfluencers[a] = data[a]
            }
            // this.listInfluencers = data
            setTimeout(() => { 
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadInfluencersNot(){
      this.isLoading = true;
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getInfluencersHome/b/'+0).then(response => {
          if(response){
            let data = response.data;
            for(let a=0; a < data.length; a++){
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            setTimeout(() => { 
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    openAll(){
      this.$router.push({name:'micro-influencer'}); 
    },
    book(talent){
      window.open('https://wa.me/6285715160130?text=Halo Admin, Saya tertarik kepada talent *@'+talent+'* untuk bekerjasama. Mohon diinfokan rate cardnya ya. \n Terima kasih', '_blank');
    },
    goToDetail(id){
      this.$router.push({name:'influencer-detail', query: {user: this.encryptData(id)}});
    },
    goToLogin(){
      this.$router.push({name:'login'});
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
